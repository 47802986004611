/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
.front .pane-profiles-panel-pane-2 {
  border-radius: 4px;
  background-color: #f6f6f6; }
  .front .pane-profiles-panel-pane-2 h2.pane-title {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 38px; }
  .front .pane-profiles-panel-pane-2 .view-header {
    width: 100%; }
    .front .pane-profiles-panel-pane-2 .view-header p {
      text-align: center;
      max-width: 450px;
      margin: 0 auto;
      margin-top: 20px; }
  .front .pane-profiles-panel-pane-2 p.see-all-faculty {
    margin-bottom: 32px; }
  .front .pane-profiles-panel-pane-2 .views-row-first {
    background-color: #fff;
    margin: 0 30px;
    min-height: 240px;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    overflow: hidden; }
    .front .pane-profiles-panel-pane-2 .views-row-first .profile-list-image {
      float: left;
      margin-right: 18px;
      border-right: 1px solid #e9e9e9; }
    .front .pane-profiles-panel-pane-2 .views-row-first p.professional-title {
      display: inline-block;
      max-width: 455px;
      font-size: 17px;
      font-style: italic;
      color: #4b4b4b;
      border-bottom: 1px solid #d8d8d8;
      margin-bottom: 5px; }
    .front .pane-profiles-panel-pane-2 .views-row-first p.specialty {
      margin-bottom: 0; }
  .front .pane-profiles-panel-pane-2 .faculty-categories {
    max-width: 375px;
    margin: 28px auto 0;
    padding-bottom: 32px; }

.front .recent-publications, .front .recent-directions {
  margin-top: 30px;
  display: inline-block;
  clear: both;
  width: 100%; }
  .front .recent-publications svg, .front .recent-directions svg {
    float: left;
    margin: 10px 30px 90px 0;
    fill: #e7751d;
    color: #cf4520;
    height: 50px;
    width: 50px;
    vertical-align: middle; }

.front .recent-publications {
  margin-top: 45px;
  border-bottom: 1px solid #d8d8d8; }

.front .homepage-news-view {
  border-bottom: 5px solid #eee; }
  .front .homepage-news-view h3.pane-title {
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 2px; }
    .front .homepage-news-view h3.pane-title a {
      color: #4b4b4b; }
      .front .homepage-news-view h3.pane-title a:after {
        line-height: 2; }
  .front .homepage-news-view .views-row {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px; }
    .front .homepage-news-view .views-row h3.teaser-title {
      font-size: 16px;
      line-height: 1.25;
      margin: 12px 0; }
      .front .homepage-news-view .views-row h3.teaser-title a:after {
        display: none; }
    .front .homepage-news-view .views-row p {
      font-size: 16px;
      line-height: 1.25; }
  .front .homepage-news-view .views-row-last {
    border-bottom: none; }
  .front .homepage-news-view .views-row a.views-more-link {
    font-size: 15px;
    width: 100%; }
    .front .homepage-news-view .views-row a.views-more-link:after {
      content: '\e80d';
      color: #e7751d;
      padding-left: 10px;
      font-size: 60%;
      vertical-align: top;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      text-decoration: none;
      line-height: 3; }

.front .homepage-events-view h3.pane-title {
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 2px; }
  .front .homepage-events-view h3.pane-title a {
    color: #4b4b4b; }
    .front .homepage-events-view h3.pane-title a:after {
      line-height: 2; }

.front .homepage-events-view .views-row {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px; }
  .front .homepage-events-view .views-row h3.teaser-title {
    font-size: 16px;
    line-height: 1.25;
    margin: 12px 0; }
    .front .homepage-events-view .views-row h3.teaser-title a:after {
      display: none; }
  .front .homepage-events-view .views-row p {
    font-size: 16px;
    line-height: 1.25; }

.front .homepage-events-view .views-row-last {
  border-bottom: none; }

.not-front .site-main .external-link:after {
  content: url(/sites/all/themes/wcm_microbio/img/external-link_red.png); }

.not-front .site-main .profile-filter .select2-container {
  width: 400px !important; }

.not-front .site-main .view-profiles .views-row {
  display: inline-block;
  width: 100%; }
  .not-front .site-main .view-profiles .views-row .views-field-field-profile-image {
    float: left;
    padding-right: 30px; }
    .not-front .site-main .view-profiles .views-row .views-field-field-profile-image img {
      width: 120px;
      padding-bottom: 10px; }
  .not-front .site-main .view-profiles .views-row .views-field-nothing {
    float: left;
    clear: left; }
  .not-front .site-main .view-profiles .views-row .display-name {
    margin: 0px;
    border-bottom: none; }
  .not-front .site-main .view-profiles .views-row .views-field-field-professional-title ul {
    list-style: none;
    margin: 0px; }
    .not-front .site-main .view-profiles .views-row .views-field-field-professional-title ul li {
      margin: 0px; }
  .not-front .site-main .view-profiles .views-row .specialty, .not-front .site-main .view-profiles .views-row .room, .not-front .site-main .view-profiles .views-row .email, .not-front .site-main .view-profiles .views-row .phone, .not-front .site-main .view-profiles .views-row .fax {
    padding-left: 150px; }

.not-front .site-main .view-profiles hr {
  margin: 0px; }

.not-front .site-main .profile h3 {
  border-bottom: none; }

.not-front .site-main .profile-list-image .provider-image {
  float: left;
  padding: 0px 30px 40px 0px; }

.not-front .site-main .publications-button:before {
  content: '|';
  padding-right: 10px;
  border-bottom: dotted 1px #fff; }

.not-front .site-main .publications-button:hover:before {
  text-decoration: none;
  border-bottom: solid 1px #fff; }

.not-front .site-main .vivo-button {
  margin-right: 10px; }

.not-front .site-main .lab-member-container {
  width: 100%;
  background-color: #f7f7f7;
  display: inline-block;
  margin: 15px 0px; }
  .not-front .site-main .lab-member-container h3.pane-title {
    text-align: center;
    padding-bottom: 20px; }
  .not-front .site-main .lab-member-container .view-lab-members .gridcol {
    height: 215px;
    padding: 20px 80px; }
    @media screen and (max-width: 767px) {
      .not-front .site-main .lab-member-container .view-lab-members .gridcol {
        height: auto; } }
    .not-front .site-main .lab-member-container .view-lab-members .gridcol .lab-member-title {
      font-weight: bold; }
  .not-front .site-main .lab-member-container .view-lab-members .gridborder {
    border-bottom: solid 2px #fff; }
  .not-front .site-main .lab-member-container .view-lab-members .col-1 {
    border-right: solid 2px #fff; }

.not-front .site-main .view-people .select2-container {
  width: 400px !important; }
  @media screen and (max-width: 767px) {
    .not-front .site-main .view-people .select2-container {
      width: 100% !important; } }

.not-front .site-main .view-people .views-row {
  display: inline-block;
  width: 100%;
  padding: 0px 0px 40px 0px;
  margin: 40px 0px 0px 0px;
  border-bottom: solid 1px #dddddd; }
  .not-front .site-main .view-people .views-row .views-field-field-person-photo {
    float: left;
    padding-right: 30px; }
    @media screen and (max-width: 767px) {
      .not-front .site-main .view-people .views-row .views-field-field-person-photo {
        float: none; } }
    .not-front .site-main .view-people .views-row .views-field-field-person-photo img {
      padding-bottom: 10px; }
  .not-front .site-main .view-people .views-row h3.person-name {
    margin: 0px; }
  .not-front .site-main .view-people .views-row p {
    margin-left: 330px; }
    @media screen and (max-width: 767px) {
      .not-front .site-main .view-people .views-row p {
        margin-left: auto; } }
  .not-front .site-main .view-people .views-row .person-email {
    margin-left: 330px; }
    @media screen and (max-width: 767px) {
      .not-front .site-main .view-people .views-row .person-email {
        margin-left: auto; } }
    .not-front .site-main .view-people .views-row .person-email span {
      font-weight: bold;
      float: left;
      padding-right: 5px; }

.not-front .site-main .view-news .select2-container {
  width: 400px !important; }

.not-front .site-main .view-news .views-row {
  padding: 0px 0px 40px 0px;
  margin: 40px 0px 0px 0px;
  border-bottom: solid 1px #dddddd; }
  .not-front .site-main .view-news .views-row .teaser-image img {
    float: right;
    margin: 0px 10px; }
  .not-front .site-main .view-news .views-row h3.teaser-title {
    margin: 0px; }
  .not-front .site-main .view-news .views-row .post-date {
    margin: 10px 0 5px;
    color: #666666;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase; }
  .not-front .site-main .view-news .views-row a.read-more {
    padding: 20px 0px 0px 0px;
    display: inline-block; }

.not-front .site-main .view-news .views-row-last {
  border-bottom: none; }

.not-front .site-main .field-name-field-news-category h3.field-label {
  margin: 0px; }

.not-front .site-main ul.social-share li a {
  border-bottom: none; }
  .not-front .site-main ul.social-share li a.hover {
    text-decoration: none; }

.not-front .site-main .cal-events-cta {
  margin: 20px 0px;
  border-top: solid 1px #dddddd;
  border-bottom: solid 1px #dddddd;
  display: inline-block;
  width: 100%; }
  .not-front .site-main .cal-events-cta img {
    float: left;
    margin: 30px 30px 25px 25px;
    padding-right: 30px;
    border-right: solid 1px #dddddd; }
  .not-front .site-main .cal-events-cta p {
    padding-top: 30px; }

.not-front .site-main .view-events .views-row {
  border-bottom: solid 1px #dddddd;
  margin: 0px 0px 25px 0px;
  padding: 20px 0px; }
  .not-front .site-main .view-events .views-row .teaser-image img {
    float: right;
    margin: 0px 10px; }
  .not-front .site-main .view-events .views-row h3.teaser-title {
    margin: 0px; }
  .not-front .site-main .view-events .views-row .views-field-field-event-date {
    text-transform: uppercase;
    margin: 10px 0 5px;
    color: #666666;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 2px; }
  .not-front .site-main .view-events .views-row .views-field-field-event-link {
    padding: 20px 0px; }

.not-front .site-main .view-events .views-row-last {
  border-bottom: none; }

.brand__lockup img {
  max-height: 84px; }

#primary-nav .level-1 {
  width: 18%;
  padding: 8px 0; }
  #primary-nav .level-1:nth-child(3) {
    width: 25%; }
  #primary-nav .level-1:nth-child(4), #primary-nav .level-1:nth-child(6) {
    width: 20%; }

#block-menu-block-1 .content {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px;
  margin-bottom: 10px; }

ul.social-share li a.external-link {
  border-bottom: none; }
  ul.social-share li a.external-link.hover {
    text-decoration: none; }
  ul.social-share li a.external-link:after {
    content: none !important; }

/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px; }

.notable-publication a {
  color: #555; }
