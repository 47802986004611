.not-front{
	.site-main{
		//EXTERNAL LINK GLYPH
		.external-link:after{
		    content: url(/sites/all/themes/wcm_microbio/img/external-link_red.png);
		}

		//PROFILES
		.profile-filter{
			.select2-container{
            	width: 400px !important;
        	}
        }

		.view-profiles{
			.views-row{
				display: inline-block;
				width: 100%;

				.views-field-field-profile-image{
					float: left;
					padding-right: 30px;

					img{
					    width: 120px;
				        padding-bottom: 10px;					
					}
				}

				.views-field-nothing{
					float: left;
					clear: left;
				}

				.display-name{
					margin: 0px;
					border-bottom: none;
				}

				.views-field-field-professional-title{
					ul{
						list-style: none;
						margin: 0px;

						li{
							margin: 0px;
						}
					}
				}

				.specialty, .room, .email, .phone, .fax{
					padding-left: 150px;
				}
			}

			hr{
				margin: 0px;
			}
		}

		//PROFILE INDIVIDUAL PAGE
		.profile{
			h3{
			    border-bottom: none;
			}
		}

		.profile-list-image{
			.provider-image{
				float: left;
				padding: 0px 30px 40px 0px;
			}
		}

		.publications-button{
			&:before{
				content: '|';
				padding-right: 10px;
				border-bottom: dotted 1px $wcm-white;
			}

			&:hover{
				&:before{
					text-decoration: none;
					border-bottom: solid 1px $wcm-white;
				}
			}
		}

		.vivo-button{
			margin-right: 10px;
		}

		.lab-member-container{
			width: 100%;
			background-color: $wcm-bg-gray;
			display: inline-block;
		    margin: 15px 0px;

			h3.pane-title{
				text-align: center;
			    padding-bottom: 20px;
			}

			.view-lab-members{

				.gridcol{
					height: 215px;
					padding: 20px 80px;

					@include breakpoint ($xs){
						height: auto;
					}

					.lab-member-title{
						font-weight: bold;
					}
				}

				.gridborder{
					border-bottom: solid 2px $wcm-white;
				}

				.col-1{
					border-right: solid 2px $wcm-white;
					
				}
			}
		}
		
		//PEOPLE
		.view-people{

			.select2-container{
                width: 400px !important;
                @include breakpoint ($xs){
                	width: 100% !important;
                }
            }

			.views-row{
				display: inline-block;
				width: 100%;
				padding: 0px 0px 40px 0px;
		        margin: 40px 0px 0px 0px;
		        border-bottom: solid 1px $wcm-border-gray;

				.views-field-field-person-photo{
					float: left;
					padding-right: 30px;

					@include breakpoint ($xs){
						float: none;
					}

					img{
				        padding-bottom: 10px;					
					}
				}

				h3.person-name{
		            margin: 0px;
		        }

		        p{
	        	    margin-left: 330px;

	        	    @include breakpoint ($xs)
	        	    {
	        	    	margin-left: auto;
	        	    }
		        }

				.person-email{
					margin-left: 330px;
					@include breakpoint ($xs)
	        	    {
	        	    	margin-left: auto;
	        	    }

					span{
						font-weight: bold;
						float: left; 
						padding-right: 5px;
					}
				}
			}
		}

		//NEWS
		.view-news{
		    .select2-container{
		        width: 400px !important;
		    }

		    .views-row{
		        padding: 0px 0px 40px 0px;
		        margin: 40px 0px 0px 0px;
		        border-bottom: solid 1px $wcm-border-gray;
		        .teaser-image{
		            img{
		                float: right;
		                margin: 0px 10px;
		            }
		        }
		        h3.teaser-title{
		            margin: 0px;
		        }

		        .post-date{
		        	margin: 10px 0 5px;
				    color: $wcm-med-gray;
				    font-size: 13px;
				    font-weight: 700;
				    letter-spacing: 2px;
				    text-transform: uppercase;

		        }

		        a.read-more{
		            padding: 20px 0px 0px 0px;
		            display: inline-block;
		        }
		    }

		    .views-row-last{
		        border-bottom: none;
		    }
		}

		.field-name-field-news-category{
		    h3.field-label{
		        margin: 0px;
		    }
		}

		ul.social-share{
		    li{
		        a{
		            border-bottom: none;
		            &.hover{
		                text-decoration: none;
		            }
		        }
		    }
		}

		//CAL CTA
		.cal-events-cta{
			margin: 20px 0px;
			border-top: solid 1px $wcm-border-gray;
			border-bottom: solid 1px $wcm-border-gray;
		    display: inline-block;
    		width: 100%;

			img{float: left;
			    margin: 30px 30px 25px 25px;
			    padding-right: 30px;
			    border-right: solid 1px $wcm-border-gray;
			}

			p{
				padding-top: 30px;
			}
		}


		//EVENTS
		.view-events{
			.views-row{
		        border-bottom: solid 1px $wcm-border-gray;
			    margin: 0px 0px 25px 0px;
			    padding: 20px 0px;

		        .teaser-image{
		            img{
		                float: right;
		                margin: 0px 10px;
		            }
		        }

		        h3.teaser-title{
		            margin: 0px;
		        }

		        .views-field-field-event-date{
		        	text-transform: uppercase;
		        	margin: 10px 0 5px;
				    color: $wcm-med-gray;
				    font-size: 13px;
				    font-weight: 700;
				    letter-spacing: 2px;
				    
		        }

		        .views-field-field-event-link{
	        	    padding: 20px 0px;
		        }
		    }

		    .views-row-last{
		        border-bottom: none;
		    }
		}
	}
}
