//LOGO
.brand__lockup img{
	max-height: 84px;
}

//NAVIGATION
#primary-nav .level-1 {
    width: 18%;
    padding: 8px 0;

    &:nth-child(3){
        width: 25%;
    }

    &:nth-child(4), &:nth-child(6){
        width: 20%;
    }
}

//BLOCK MENU
#block-menu-block-1 .content {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    column-gap: 15px;
    margin-bottom: 10px;
}


//SOCIAL SHARE REMOVE LINK GLYPH
ul.social-share{
    li{
        a.external-link{
            border-bottom: none;
            &.hover{
                text-decoration: none;
            }
            &:after{
                content: none !important;
            }
        }
    }
}