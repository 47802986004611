.front{
	//PROFILE
	.pane-profiles-panel-pane-2{
		border-radius: 4px;
		background-color: #f6f6f6;

		h2.pane-title{
			text-align: center;
			padding-top: 30px;
			padding-bottom: 30px;
			font-size: 38px;
		}

		.view-header{
			width: 100%;
			p{
				text-align: center;
				max-width: 450px;
				margin: 0 auto;
				margin-top: 20px;
			}
		}
		p.see-all-faculty{
			margin-bottom: 32px;
		}

		.views-row-first{
			background-color: $wcm-white;
			margin: 0 30px;
			min-height: 240px;
			border: 1px solid #e9e9e9;
			border-radius: 4px;
			overflow: hidden;
			.profile-list-image{
				float: left;
				margin-right: 18px;
				border-right: 1px solid #e9e9e9;
			}
			p.professional-title{
				display: inline-block;
				max-width: 455px;
				font-size: 17px;
				font-style: italic;
				color: #4b4b4b;
				border-bottom: 1px solid #d8d8d8;
				margin-bottom: 5px;
			}
			p.specialty{
				margin-bottom: 0;
			}

		}

		.faculty-categories{
			max-width: 375px;
			margin: 28px auto 0;
			padding-bottom: 32px;
		}
	}
	//PUBLICATIONS
	.recent-publications, .recent-directions{
		margin-top: 30px;
		display: inline-block;
		clear: both;
		width: 100%;
		svg{
			float: left;
			margin:10px 30px 90px 0;
			fill: $wcm-bright-orange;
		    color: $wcm-dark-orange;
		    height: 50px;
		    width: 50px;
		    vertical-align: middle;
		}
	}

	.recent-publications{
		margin-top: 45px;
		border-bottom: 1px solid #d8d8d8;
	}

	//HOMEPAGE NEWS AND EVENTS TEASER
	@mixin homepage-views{
		h3.pane-title{
			text-transform: uppercase;
			font-family: 'Open Sans', sans-serif;
			font-size: 16px;
			font-weight: bold;
			letter-spacing: 2px;
			a{
				color: #4b4b4b;
				&:after{
					line-height: 2;
				}
			}
		}
		
		.views-row{
		    border-bottom: 1px solid #eee;
	        padding-bottom: 10px;

			h3.teaser-title{
				font-size: 16px;
				line-height: 1.25;
				margin: 12px 0;
				a{
					&:after{
						display: none;
					}
				}
			}
			p{
				font-size: 16px;
				line-height: 1.25;
			}
		}

		.views-row-last{
		    border-bottom: none;
	    }
	}
	.homepage-news-view{
		@include homepage-views();
		border-bottom: 5px solid #eee;

		.views-row{
			a.views-more-link{
				font-size: 15px;
				width: 100%;
		    	&:after{
		    		content: '\e80d';
		    		color: $wcm-bright-orange;
				    padding-left: 10px;
				    font-size: 60%;
				    vertical-align: top;
				    font-family: "fontello";
				    font-style: normal;
				    font-weight: normal;
				    speak: none;
				    display: inline-block;
				    text-decoration: inherit;
				    text-align: center;
				    font-variant: normal;
				    text-transform: none;
				    text-decoration: none;
				    line-height: 3;
		    	}
			}
		}
	}
	.homepage-events-view{
		@include homepage-views();
	}
}